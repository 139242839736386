

    @font-face {
        font-family: 'MuseoModerno Light';
        src: url('./fonts/MuseoModerno-Light.eot');
        src: url('./fonts/MuseoModerno-Light.eot?#iefix') format('embedded-opentype'),
            url('./fonts/MuseoModerno-Light.woff2') format('woff2'),
            url('./fonts/MuseoModerno-Light.woff') format('woff'),
            url('./fonts/MuseoModerno-Light.ttf') format('truetype'),
            url('./fonts/MuseoModerno-Light.svg#MuseoModerno-Light') format('svg');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'MuseoModerno';
        src: url('./fonts/MuseoModerno-Regular.eot');
        src: url('./fonts/MuseoModerno-Regular.eot?#iefix') format('embedded-opentype'),
            url('./fonts/MuseoModerno-Regular.woff2') format('woff2'),
            url('./fonts/MuseoModerno-Regular.woff') format('woff'),
            url('./fonts/MuseoModerno-Regular.ttf') format('truetype'),
            url('./fonts/MuseoModerno-Regular.svg#MuseoModerno-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

/* ************************************************************************************************************************************************ */

.BackgroundInicio{
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 240rem;
    background: #FFC1A0;
}

.Vector4{
   padding-top: 42.563rem;
}

.Footer{
    position: absolute;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    top: 100%;
    width: 100%;
    height: 281px; 
    background: #FFFFFF;
    justify-content: space-evenly;
    align-items: center;
  }

.enlacesFooter{
    text-decoration: none;
    color: #262626;
    margin-left: 16px;
}

.enlacesFooter:visited{
    color: #262626;
}

@media screen and (max-width: 1050px){
    .Footer{
        display: flex;
        flex-direction: column;
        height: 841px;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1281px){
    .BackgroundInicio{
        width: 100%;
    }
    
    .Vector4{
       padding-top: 10rem;
    }
}

@media screen and (max-width: 1050px){
    .BackgroundInicio{
        height: 250rem;
    }
    
    .Vector4{
       padding-top: 18rem;
    }
}


.Support,.AboutUs{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titulo{
    font-size: 20px;
    font-weight: 600;
    font-family: 'MuseoModerno';
    color: #262626;
    width: 253px;
    height: 48px;
    position: absolute;
    margin-top: 0;
    display: flex;
    align-items: center;
}
  
.lista{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    width: 253px;
    height: 150px;
    left: 294px;
    top: 64.5px;
    font-family: 'MuseoModerno';
    font-style: normal;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    margin-top: 68px;
}
  
li{
    list-style: none;
}
  
.botonApp{
    display: flex;
    padding: 10px;
    gap: 10px;
  
    position: absolute;
  
    background: #F0E400;
    border: 2px solid #F0E400;
    border-radius: 20px;
  
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #415856;
    margin-top: 2.375rem;
}
  
.LaunchApp{
    margin-right: 2%;
    justify-content: center;
    display: flex;
}

.ContentsInicio{
    margin: auto;
    position: absolute;
    width: 71.25rem;
    height: 85.688rem;
    left: 4.375rem;
    right: 4rem;
    top: 4.938rem;
    max-width: 100%;
    background: linear-gradient(294.61deg, #313645 14.41%, #4D5757 34.9%, #6B7B6B 57.57%, #526F62 91.87%);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

@media screen and (max-width: 1280px){
    .ContentsInicio{
        width: 80%;
    }
}

@media screen and (max-width: 820px){
    .ContentsInicio{
        margin: 0 auto;
        width: 571px;
        height: 1175px;
        left: 40px;
        top: 31px;
        
        background: linear-gradient(290.04deg, #313645 -61.77%, #4D5757 -24.25%, #6B7B6B 17.24%, #526F62 80.05%);
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
}

.Vector5{
    position: absolute;
    width: 71.25rem;
    bottom: 0;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

@media screen and (max-width: 1280px){
    .Vector5{
        width: 100%;
        height: 66%;
    }
}

@media screen and (max-width: 1050px){
    .Vector5{
        width: 100%;
        height: 72.25%;
    }
}

@media screen and (max-width: 820px){
    .Vector5{
        display: none;
    }
}

.HeaderInicio{
    position: absolute;
    width: 63.438rem;
    height: 2.813rem;
    top: 3.188rem;
    left: 4rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1280px){
    .HeaderInicio{
        width: 90%;
        left: 40px;
    }  
}

@media screen and (max-width: 1050px){
    .HeaderInicio{
        width: 88%;
    }  
}

@media screen and (max-width: 820px){
    .HeaderInicio{
        width: 534px;
        height: 45px;
        left: 20px;
        top: 18px;
    }  
}

.log{
    height: 100%;
}

.HeaderBotonesInicio{
    position: relative;
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.ConnectInicio{
    padding: 8px 0;
  
    position: relative;
    width: 128px;
    height: 45px;
  
    background: #F0E400;
    border: 2px solid #F0E400;
    border-radius: 20px;
  
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 2px;
  
    color: #415856;
}

.LanguageInicio{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 5px;
  
    width: 95px;
    height: 45px;
  
    background: #FFFFFF;
    border: 0;
    border-radius: 15px;
  
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 2px;
  
    color: #415856;
}

.bandera{
    width: 33%;
}

.actions{
    width: 45px;
    height: 100%;
}

.triangulo-redondeado,
.triangulo-redondeado:before,
.triangulo-redondeado:after {
  position: relative;
  display:block;
	width:  27px;
	height: 27px;
	background: #FFF;
	border-top-right-radius: 13.5px;
}
.triangulo-redondeado {
  margin: 4px auto 0 auto;
  transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}
.triangulo-redondeado:before,
.triangulo-redondeado:after {
	content: '';
	position: absolute;
}
.triangulo-redondeado:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.triangulo-redondeado:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.list{
    position: absolute;
    width: 160px;
    height: 145px;
    z-index: 90;
    top: 45px;
    left: 608px;
}

@media screen and (max-width: 1280px){
    .list{
        left: 85.25%;
    }
  }
  
  @media screen and (max-width: 1050px){
    .list{
        left: 80.2%;
    }
  }
  
  @media screen and (max-width: 820px){
    .list{
        left: 72%;
    }
  }

.ActionList{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    margin-top: 20px;
    width: 120px;
    height: 125px;
    border-radius: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 9;
}

.ContactList{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
    width: 120px;
    height: 210px;
    margin-top: 135px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 9;
}

.li{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    
    width: 160px;

    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    right: 40px;
    color: #415856;
}

.li2{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 160px;
    position: relative;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    right: 40px;
    height: 24px;
}

.ActionsInicio{
    z-index: 7;
    width: 45px;
}

.columna{
    position: absolute;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 1197.5px;
    top: 10.813rem;
}

@media screen and (max-width: 820px){
    .columna{
        height: 1072px;
        top: 103px;
    } 
}

.Titulo{
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 1700px;
}

@media screen and (max-width: 1280px){
    .Titulo{
        height: 460px;
    }
}

@media screen and (max-width: 1050px){
    .Titulo{
        height: 400px;
    }
}

@media screen and (max-width: 820px){
    .Titulo{
        height: 376.5px;
    }
}

.TextoTitulo{
    margin-left: 64px;
    position: absolute;
    width: 559px;
    height: 301px;

}

@media screen and (max-width: 1280px){
    .TextoTitulo{
        width: 51%;
        margin-left: 40px;
    }    
}

@media screen and (max-width: 1050px){
    .TextoTitulo{
        margin-left: 40px;
        width: 51%;
    }    
}

@media screen and (max-width: 820px){
    .TextoTitulo{
        margin-left: 21px;
        width: 533px;
        height: 178px;
        margin-top: 199px;
    }    
}

.h3{
    position: relative;
    width: 34.938rem;
    height: 9.75rem;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 400;
    font-size: 61px;
    line-height: 78px;
    letter-spacing: -1px;
    color: #F0E400;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
}

@media screen and (max-width: 1280px){
    .h3{
        width: 100%;
        font-size: 46.5px;
        margin-left: 0;
    }
}

@media screen and (max-width: 1050px){
    .h3{
        font-size: 36.5px;
    }
}

@media screen and (max-width: 820px){
    .h3{
        width: 533px;
        height: 59px;
        font-size: 31px;
        line-height: 50px;
    }
}

.vector6{
    position: relative;
    background: #F0E400;
    margin-bottom: 8px;
    border-radius: 20px;
}

@media screen and (max-width: 820px){
    .vector6{
        width: 34px;
        height: 2px;
        bottom: 16.75px;
    }
}


.parrafo{
    position: relative;
    width: 34.625rem;
    height: 6.375rem;

    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;

    letter-spacing: -0.5px;

    color: #FFFFFF;
    margin-top: 21.5px;
}

@media screen and (max-width: 1280px){
    .parrafo{
        width: 98%;
        font-size: 23px;
    }
}


@media screen and (max-width: 1050px){
    .parrafo{
        width: 86%;
        font-size: 22px;
    }
}

@media screen and (max-width: 820px){
    .parrafo{
        width: 533px;
        height: 102px;
        font-size: 22px;
        bottom: 7.25px;
        margin-top: 0;
    }
}

.ImagenesTitulo{
    position: absolute;
    width: 674px;
    height: 635px;
    bottom: 635px;
    left: 466px;
}

@media screen and (max-width: 1280px){
    .ImagenesTitulo{
        width: 62%;
        left: 38%;
    }
}

@media screen and (max-width: 1050px){
    .ImagenesTitulo{
        width: 57%;
        left: 43%;
    }
}

@media screen and (max-width: 820px){
    .ImagenesTitulo{
        width: 542px;
        height: 191px;
        left: 21px;
        bottom: 881px;
        background: url("../public/Imagenes/gatoPorcentaje.png") no-repeat;
        background-size: 100%;
    }
}

.imagen{
    position: relative;
    width: 100%;
}

@media screen and (max-width: 1280px){
    .imagen{
        width: 100%;
        margin-top: 11px;
    }
}

@media screen and (max-width: 1050px){
    .imagen{
        width:100%;
        margin-left:0;
        margin-top: 25px;
    }
}

@media screen and (max-width: 820px){
    .imagen{
        display: none;
    }
}

.gato2{
    position: absolute;
    width: 324px;
    height: 332px;
    top: 335px;
    left: 278px;
    z-index: 5;
}

@media screen and (max-width: 1280px){
    .gato2{
        width: 270px;
        height: 276px;
        left: 43%;
        top: 0;
        margin-top: 51.5%;
    }
}

@media screen and (max-width: 1050px){
    .gato2{
        width: 210px;
        height: 215px;
        left: 41%;
        top: 2.5%;
    }
}

@media screen and (max-width: 820px){
    .gato2{
       display: none;
    }
}

.Descripcion{
    flex-direction: row;
    display: flex;
    height: 586px;
}

@media screen and (max-width: 1050px){
    .Descripcion{
        height: 797.5px;
    }
}

@media screen and (max-width: 820px){
    .Descripcion{
        height: 695px;
    }
}

.mobile{
    width: 60%;
    margin-left: 8%;
    margin-top: 43px;
}

@media screen and (max-width: 1280px){
    .mobile{
        display: none;
    }
}

.TextoDescripcion{
    position: absolute;
    width: 609px;
    height: 586px;
    left: 467px;
    top: 612px;

    background: #FFFFFF;
    box-shadow: 0 1px 7px 6px rgba(0, 0, 0, 0.05);
    border-radius: 30px 30px 0 0;
}

@media screen and (max-width: 1280px){
    .TextoDescripcion{
        width: 70%;
        height: 677px;
        left: 15%;
        top: 521px;
    }
}

@media screen and (max-width: 1050px){
    .TextoDescripcion{
        position: relative;
        width: 600px;
        height: 49.85rem;
        margin: auto;
        top: 0;
        left: 0;
        box-shadow: none;
        filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.25));
    }
}

@media screen and (max-width: 820px){
    .TextoDescripcion{
        width: 534px;
        height: 695px;
    }
}

.h5{
    position: absolute;
    width: 30.957rem;
    height: 5.688rem;
    left: 3.375rem;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -1px;
    color: #E6007E;
    margin-top: 44px;
}

@media screen and (max-width: 1280px){
    .h5{
        font-size: 36.5px;
        margin-left: 4.5px;
    }
}

@media screen and (max-width: 1050px){
    .h5{
        font-size: 0;
        width: 500px;
        height: 165px;
        background: url("../public/Imagenes/ourProduct.png") no-repeat;
        background-size: 100%;
        margin-top: 45px;
        left: 45px;
    }
}

@media screen and (max-width: 820px){
    .h5{
        width: 450px;
        height: 150px;
        margin-top: 40px;
        left: 38px;
    }
}

.vector7{
    position: absolute;
    width: 38px;
    height: 2px;
    left: 3.375rem;
    top: 103px;

    background: #E6007E;
    border-radius: 20px;

}

@media screen and (max-width: 1280px){
    .vector7{
        width: 30.75px;
        left: 61px;
    }
}

@media screen and (max-width: 1050px){
    .vector7{
        display: none;
    }
}

.descripcion{
    position: absolute;
    width: 31.313rem;
    height: 27.563rem;
    left: 3.375rem;
    top: 110px;

    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;

    letter-spacing: -0.5px;

    color: #415856;
}

@media screen and (max-width: 1280px){
    .descripcion{
        width: 82%;
        height: 495px;
        left: 2.75rem;
        margin-left: 2%;
        top: 150px;
    }
}

@media screen and (max-width: 1050px){
    .descripcion{
        width: 500px;
        left: 2.35rem;
        height: 460px;
        top: 260px;
    }
}

@media screen and (max-width: 820px){
    .descripcion{
        width: 454px;
        height: 446px;
        left: 32px;
        top: 190px;
    }
}

.enlace{

    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 90px;
    text-decoration: none;

    letter-spacing: -0.5px;

    color: #E6007E;
}

@media screen and (max-width: 1280px){
    .enlace{
        line-height: 105px;
    }
}

@media screen and (max-width: 820px){
    .enlace{
        line-height: 87px;
    }
}

.WhyUs{
    position: absolute;
    width: 71.25rem;
    height: 26.563rem;
    left: 4.438rem;
    top: 97rem;
    right: 4rem;
}

@media screen and (min-width: 1281px){
    .WhyUs{
        margin: auto;
    }
    
}

@media screen and (max-width: 1280px){
    .WhyUs{
        width: 80%;
        left: 10%;
        right: 0;
        top: 94.5rem;
    }
}

@media screen and (max-width: 1050px){
    .WhyUs{
        top:  95.813rem;
        height: 68.65rem;
    }
}

@media screen and (max-width: 820px){
    .WhyUs{
        margin: 0 auto;
        width: 571px;
        top:  78rem;
        left: 0;
        right: 2%;
        height: 1231px;
    }
}

.tituloWhyUs{
    margin-top: 0;

    position: absolute;
    text-align: center;
    width: 71.188rem;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    letter-spacing: -1px;

    color: #000000;
}

@media screen and (max-width: 1280px){
    .tituloWhyUs{
        width: 100%;
        font-size: 37px;
    }
}

@media screen and (max-width: 820px){
    .tituloWhyUs{
        width: 571px;
        height: 65px;
        font-size: 36px;
        left: 0;
        margin: 0 auto;
    }
}

.cajas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6.875rem;
}

@media screen and (max-width: 1050px){
    .cajas{
        flex-direction: column;
        justify-content: space-around;
    }
}

@media screen and (max-width: 820px){
    .cajas{
        margin-top: 105px;
    }
}

.Caja1{
    position: relative;
    width: 23.125rem;
    height: 20rem;

    background: #FFFFFF;

    border-radius: 59.3744px;

    flex-direction: column;
    display: flex;
    text-align: center;
}

@media screen and (max-width: 1280px){
    .Caja1{
        width: 32%;
        height: 25rem;
    }
}

@media screen and (max-width: 1050px){
    .Caja1{
        width: 100%;
        height: 18.5rem;
    }
}

@media screen and (max-width: 820px){
    .Caja1{
        width: 573px;
        height: 342px;
        border-radius: 30px;
    }
}

.imagen1{

    width: 65px;
    height: 65px;

    display: block;
    margin: 0 auto;

    margin-top: -20px;
}

.titulo1{
    position: relative;
    
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    
    letter-spacing: -0.5px;
    margin-top: 17px;
    
    color: #E6007E;
}

@media screen and (max-width: 1172px){
    .titulo1{
        margin-top: 0;
    }
}

@media screen and (max-width: 1050px){
    .titulo1{
        font-size: 30px;
        margin-top: 17px;
    }
}

.descripcion1{
    position: relative;
    
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;

    letter-spacing: -0.5px;

    color: #262626;
    margin-left: 1.5rem;
    margin-right: 1.5rem; 
    margin-top: -17px;
}

@media screen and (max-width: 1280px){
    .descripcion1{
        margin-top: -10px;
    }
}

@media screen and (max-width: 1172px){
    .descripcion1{
        margin-top: -22px;
    }
}

@media screen and (max-width: 1050px){
    .descripcion1{
        margin-left: 4rem;
        margin-right: 4rem; 
        margin-top: -17px;
    }
}

@media screen and (max-width: 820px){
    .descripcion1{
        font-size: 22px;
        margin-left: 7rem;
        margin-right: 7rem; 
    }
}

.Caja2{
    position: relative;
    width: 23.125rem;
    height: 20rem;

    background: #FFFFFF;

    border-radius: 59.3744px;

    flex-direction: column;
    display: flex;
    text-align: center;
}

@media screen and (max-width: 1280px){
    .Caja2{
        width: 32%;
        height: 25rem;
    }
}

@media screen and (max-width: 1050px){
    .Caja2{
        margin-top: 50px;
        width: 100%;
        height: 18.5rem;
    }
}

@media screen and (max-width: 820px){
    .Caja2{
        width: 573px;
        height: 342px;
        border-radius: 30px;
    }
}

.imagen2{

    width: 65px;
    height: 65px;

    display: block;
    margin: 0 auto;

    margin-top: -20px;
}

.titulo2{
    position: relative;
    
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    
    letter-spacing: -0.5px;
    margin-top: 17px;
    
    color: #E6007E;
}

@media screen and (max-width: 1172px){
    .titulo2{
        margin-top: 0;
    }
}

@media screen and (max-width: 1050px){
    .titulo2{
        font-size: 30px;
        margin-top: 17px;
    }
}

.descripcion2{
    position: relative;
    
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;

    letter-spacing: -0.5px;

    color: #262626;
    margin-left: 1.5rem;
    margin-right: 1.5rem; 
    margin-top: -17px;
}

@media screen and (max-width: 1280px){
    .descripcion2{
        margin-top: -10px;
    }
}

@media screen and (max-width: 1172px){
    .descripcion2{
        margin-top: -22px;
    }
}

@media screen and (max-width: 1050px){
    .descripcion2{
        margin-left: 4rem;
        margin-right: 4rem; 
        margin-top: -17px;
    }
}

@media screen and (max-width: 820px){
    .descripcion2{
        font-size: 22px;
        margin-left: 7rem;
        margin-right: 7rem; 
    }
}

.Caja3{
    position: relative;
    width: 23.125rem;
    height: 20rem;

    background: #FFFFFF;

    border-radius: 59.3744px;

    flex-direction: column;
    display: flex;
    text-align: center;
}

@media screen and (max-width: 1280px){
    .Caja3{
        width: 32%;
        height: 25rem;
    }
}

@media screen and (max-width: 1050px){
    .Caja3{
        margin-top: 50px;
        width: 100%;
        height: 18.5rem;
    }
}

@media screen and (max-width: 820px){
    .Caja3{
        width: 573px;
        height: 342px;
        border-radius: 30px;
    }
}

.imagen3{

    width: 65px;
    height: 65px;

    display: block;
    margin: 0 auto;

    margin-top: -20px;
}

.titulo3{
    position: relative;
    
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    
    letter-spacing: -0.5px;
    margin-top: 17px;
    
    color: #E6007E;
}

@media screen and (max-width: 1172px){
    .titulo3{
        margin-top: 5px;
    }
}

@media screen and (max-width: 1050px){
    .titulo3{
        font-size: 30px;
        margin-top: 17px;
    }
}

.descripcion3{
    position: relative;
    
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;

    letter-spacing: -0.5px;

    color: #262626;
    margin-left: 1.5rem;
    margin-right: 1.5rem; 
    margin-top: -17px;
}

@media screen and (max-width: 1280px){
    .descripcion3{
        margin-top: -10px;
    }
}

@media screen and (max-width: 1172px){
    .descripcion3{
        margin-top: -22px;
    }
}


@media screen and (max-width: 1050px){
    .descripcion3{
        margin-left: 4rem;
        margin-right: 4rem; 
        margin-top: -17px;
    }
}

@media screen and (max-width: 820px){
    .descripcion3{
        font-size: 22px;
        margin-left: 7rem;
        margin-right: 7rem; 
    }
}

.Transparency{
    flex-direction: column;
    display: flex;
    text-align: center;

    position: absolute;
    width: 71.188rem;
    height: 37.125rem;
    left: 4.438rem;
    top: 129rem;
    right: 4rem;
    
    background: #FFFFFF;
    border-radius: 30px;

}

@media screen and (min-width:1281px) {
    .Transparency{
        margin: auto;
    }
}

@media screen and (max-width: 1280px){
    .Transparency{
        width: 80%;
        left: 10%;
        top: 134rem;
    }
}

@media screen and (max-width: 1050px){
    .Transparency{
        height: 35rem;
        top: 172rem;
        filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.1));
    }
}

@media screen and (max-width: 820px){
    .Transparency{
        top: 165rem;
        width: 571px;
        margin: 0 auto;
        left: 5%;
    }
}

.tituloTransparency{
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    letter-spacing: -1px;

    color: #E6007E;
}

@media screen and (max-width: 1050px) {
    .tituloTransparency{
        margin-top: 15px;
        letter-spacing: -0.5px;
    }
}

.textoTransparency{
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    margin-top: -2rem;

    letter-spacing: -1px;

    color: #415856;
}

@media screen and (max-width: 1050px) {
    .textoTransparency{
        margin-top: -2.5rem;
        letter-spacing: -0.5px;
    }
}

@media screen and (max-width: 1005px) {
    .divTransactions{
        width: 100%;
        height: 385px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .divTransactions::-webkit-scrollbar {
        width: 100%;
        height: 10px;
    }
    
    .divTransactions::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    
    .divTransactions::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #616161 ;
        border: 1px solid #BDBDBD ;
    }

    .divTransactions::-webkit-scrollbar-thumb:hover {
        background: #424242;
    }
}

.tablaTransparency{
    width: 97%;
    position: relative;
    margin-top: 0;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    margin-left: 1.5%;
}

@media screen and (max-width: 1050px) {
    .tablaTransparency{
        font-size: 12.5px;
        margin-top: -10px;
        margin-left: 0;
        margin: 0 auto;
        width: 790px;
    }
}

.tituloTablaTransparency{
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    color:#45494B;
}

@media screen and (max-width: 1050px) {
    .tituloTablaTransparency{
        font-size: 19px;
        color:#45494B;
    }
}

.trTransparency{
    height: 45px;
}

.linksTransaction{
    color:cornflowerblue;
    text-decoration: none;
}

.linksTransaction:visited{
    color:cornflowerblue;
}

.method{
    font-size: 11px;
    background-color: #FFC1A0;
    height: 20px;
    padding-top: 2.5px;
}

.enlaceTxn{
    text-decoration: none;
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    color: #E6007E;

    position: absolute;

    margin-top: 557px;
    margin-left: 37%;
}

@media screen and (max-width: 1280px) {
    .enlaceTxn{
        font-size: 20px;
        margin-left: 0;
        align-self: center;
    }
}


@media screen and (max-width:1050px) {
    .enlaceTxn{
        font-size: 17px;
        margin-top: 527px;
    }
}

.Blog{
    position: absolute;
    width: 71.25rem;
    height: 45rem;
    left: 4.375rem;
    top: 173rem;
    right: 4rem;

    background: #FFFFFF;
    border-radius: 30px;
    text-align:justify; 
}

@media screen and (min-width:1281px) {
    .Blog{
        margin: auto;
    }
}

@media screen and (max-width: 1280px){
    .Blog{
        width: 80%;
        height: 50rem;
        left: 10%;
        top: 179rem;
    }
}

@media screen and (max-width: 1050px){
    .Blog{
        height: 28rem;
        top: 214.3rem;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 820px){
    .Blog{
        top: 210rem;
        margin: 0 auto;
        width: 571px;
        left: 5%;
    }
}

.tituloBlog{
    position: absolute;
    width: 381px;
    height: 65px;
    left: 61px;

    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;

    letter-spacing: -1px;

    color: #E6007E;
}

.vectorBlog{
    position: absolute;
    width: 40px;
    height: 2px;
    left: 62px;
    top: 99px;

    background: #E6007E;
    border-radius: 20px;
}

.fechaBlog{
    width: 30%;
    margin-left: 5%;
    margin-top: 15%;
        
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    
    letter-spacing: -0.5px;
    
    color: #262626;
}

.DescripcionBlog{
    width: 90%;
    margin-left: 5%;
        
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: bolder;
    font-size: 26px;
    
    letter-spacing: -0.5px;
    
    color: black;

}

.textoBlog{
    width: 50%;
    margin-left: 5%;
        
    font-family: 'MuseoModerno Light';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    
    letter-spacing: -0.5px;
    
    color: #262626;
}

.logo2{
    position: absolute;
    width: 25%;
    margin-left: 65%;
    margin-top: -250px;
}

@media screen and (max-width: 1050px){
    .logo2{
        display: none;
    }

    .fechaBlog{
        width: 20%;
        margin-left: 80%;
        margin-top: 15%;
            
        font-family: 'MuseoModerno Light';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        
        letter-spacing: -0.5px;
        
        color: #262626;

    }

    .DescripcionBlog{
        width: 90%;
        margin-left: 5%;
            
        font-family: 'MuseoModerno Light';
        font-style: normal;
        font-weight: bolder;
        font-size: 22px;
        
        letter-spacing: -0.5px;
        
        color: black;
    
        text-decoration: none;
    }

    .textoBlog{
        width: 90%;
        margin-left: 5%;
            
        font-family: 'MuseoModerno Light';
        font-style: normal;
        font-weight: 300;
        font-size: 21px;
        
        letter-spacing: -0.5px;
        
        color: #262626;
    }
}

.enlaces{
    text-decoration: none;
    color: #415856;
}

.enlaces:visited{
    color: #415856;
}

.enlaces4{
    text-decoration: none;
    color: white;
}

.enlaces4:visited{
    color: white;
}

.commingSoon{
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 9px;
    letter-spacing: 0;
    margin-top: -4px;

    color: #415856;
}

@media screen and (max-width: 1250px){
    .commingSoon{
        width: 0;
        font-size: 0;
    }
}

.buttonEN{
    display: none;
    position: relative;
    align-items: center;
    padding: 0 10px;
    width: 95px;
    height: 45px;
    background-color: #FFFFFF;
    border: 0;
    border-radius: 15px;
    font-family: 'MuseoModerno';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2px;
    background-image: url(../public/Imagenes/bandera.png);
    background-position: 83%;
    background-repeat: no-repeat;
    background-size: 27%;
    z-index: 85;

}

.idiomaBoton{
    color: #415856;
}

.language{
    position: relative;
    height: 90px;   
    top: 22px;
}

.MenuMovil{
    display: none;
}

.MainBlock{
    display: none;
}

.BannerMovil{
    display: none;
}

.menu{
    display: none;
}

.divBotonLaunch{
    display: none;
}

@media screen and (max-width: 651px) {

    .ContentsInicio{
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 0;
    }
    
    .ConnectInicio{
        display: none;
    }

    .HeaderBotonesInicio{
        position: absolute;
        width: 50%;
        left: 50%;
    }

    .language{
        margin-right: 53px;
    }

    .LanguageInicio{
        font-size: 0;
        width: 70px;
        gap: 5px;
        padding-left: 0;
        border: 2px solid #FFFFFF;
    }

    .bandera{
        width: 45%;
    }

    .buttonEN{
        width: 70px;
        font-size: 15px;
        background-size: 36%;
        padding-left: 6px;
    }
    
    .actions{
        display: none;
    }

    .HeaderInicio{
        top: 21px;
        left: 9%;
        width: 82%;
    }

    .MenuMovil{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 15px;
    }

    .ImagenesTitulo{
        background: none;
        display: none;
    }

    .gato2{
        display: none;
    }

    .imagen{
        display: none;
    }

    .MainBlock{
        display: flex;
        position: absolute;
        top: 8%;
    }

    .blockimagen{
        width: 80%;
        margin-left: 10%;
    }

    .TextoTitulo{
        margin-top: 50%;
        width: 87%;
        margin-left: 7%;
    }

    .h3{
        width: 100%;
        font-size: 30.5px;
        line-height: 34px;
        height: 88px;
    }

    .vector6{
        bottom: 42px;
        width: 34px;
        height: 2.98px;
    }

    .parrafo{
        width: 95%;
        font-size: 20px;
        line-height: 26px;
        height: 76.5px;
        bottom: 13px;
    }

    .divBotonLaunch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;
        width: 100%;
        height: 90px;
        top: 53.5%;
        margin-top: 45%;
    }

    .botonLaunch{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 85%;
        height: 45px;
        background: #F0E400;
        border: 2px solid #F0E400;
        border-radius: 20px;
        font-family: 'MuseoModerno';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 2px;
        color: #415856;
    }

    .TextoDescripcion{
        position: absolute;
        margin-top: 48%;
        top: 25%;
        width: 100%;
        left: 0;

        height: 700px;
        border-radius: 0;
    }

    .BannerMovil{
        display: flex;
        position: absolute;
    }

    .bannerimagen{
        width: 100%;
    }

    .h5{
        background: none;
        display: none;
    }

    .descripcion{
        top: 0;
        margin-top: 40%;
        font-size: 18px;
        width: 90%;
        left: 4%;
        height: 500px;
    }

    .enlace{
        font-size: 18px;
        line-height: 100px;
    }

    .WhyUs{
        width: 100%;
        top: 1400px;
        left: 0;
        margin-left: 0;
    }

    .tituloWhyUs{
        width: 100%;
    }

    .titulo1,.titulo2,.titulo3{
        font-size: 26px; 
    }

    .Caja1{
        width: 87%;
        height: 320px;
        margin: 0 auto;
    }

    .descripcion1{
        width: 90%;
        margin-left: 5%;
        margin-right: 0;
        font-size: 20px; 
    }

    .Caja2{
        width: 87%;
        height: 320px;
        margin: 0 auto;
        margin-top: 45px;
    }

    .descripcion2{
        width: 90%;
        margin-left: 5%;
        margin-right: 0;
        font-size: 20px; 
    }

    .Caja3{
        width: 87%;
        height: 320px;
        margin: 0 auto;
        margin-top: 45px;
    }

    .descripcion3{
        width: 90%;
        margin-left: 5%;
        margin-right: 0;
        font-size: 20px; 
    }

    .Transparency{
        width: 100%;
        height: 33.5rem;
        top: 2700px;
        left: 0;
        border-radius: 0;
    }

    .tituloTransparency{
        font-size: 30px;
        letter-spacing: -0.5px;
    }

    .textoTransparency {
        margin-top: -2rem;
        font-size: 20px;
    }

    .enlaceTxn{
        position: absolute;
        width: 100%;
        margin-top: 507px;
        margin-left: 0;
    }

    .Blog{
        width: 90%;
        height: 450px;
        top: 3360px;
    }

    .tituloBlog{
        margin-left: -30px;
        width: 50%;
        font-size: 32px;
    }

    .vectorBlog{
        margin-left: -30px;
        margin-top: -10px;
    }

    .fechaBlog{
        width: 30%;
        margin-left: 65%;
    }

    .DescripcionBlog{
        position: absolute;
        font-size: 20px;
        margin-top: 110px;
        top: 0;
    }

    .textoBlog{
        position: absolute;
        font-size: 18px;
        margin-top: 150px;
        top: 0;
        text-align: left;
    }

    .menu{
        position: absolute;
        display: flex;
        background-color: white;
        width: 282px;
        height: 250px;
        border-radius: 20px;
        z-index: 85;
        margin-top: 200px;
        right: -14px;
    }

    .headerMenu{
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin-top: 5%;
        position: absolute;
        width: 100%;
        height: 45px;
    }

    .menuTitulo{
        font-family: 'MuseoModerno';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        line-height: 9px;
        letter-spacing: 0;
        margin-left: 10%;
        position: relative;
        height: 26px;
        width: 70px;
        margin-top: 0;
        padding-top: 18px;
    
    }

    .menuCerrar{
        margin-right: 10%;
    }

    .list{
        display: none;
    }

    .list2{
        position: relative;
        width: 100%;
        height: 150px;
        z-index: 5;
        top: 125px;
        left: 0;
    }

    .ActionList{
        display: none;
    }

    .ActionList2{
        background-color: #E6007E;
        color: white;
        width: 85.85%;
        border-radius: 0 0 15px 15px;
        margin-left: 0;
        height: 150px;
        margin-top: 0;
        z-index: 85;
    }

    .li{
        width: 282px;
    }

    .li2{
        display: none;
    }

    .ContactList{
        position: absolute;
        background-color:#E6007E ;
        width: 85.8%;
        margin-left: 0;
        top: 15px;
        height: 175px;
        border-radius: 0 0 20px 20px;
        z-index: 85;
        gap: 10px;
        margin-top: 135px;
        justify-content: flex-start;
        flex-direction: column;
        display: flex;
    }

    .li5{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 282px;
        font-family: 'MuseoModerno';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        right: 40px;
        height: 24px;
    }

}

@media screen and (max-width: 611px) {
    .vector6{
        bottom: 10px;
    }
}   


@media screen and (max-width: 313px) {
    .vector6{
        margin-top: 30%;
    }
}   

@media screen and (max-width:510px){
    .TextoDescripcion{
        height: 680px;
    }
    
}

@media screen and (max-width:467px){
    .TextoDescripcion{
        height: 700px;
    }
    
}

@media screen and (max-width:450px){
    .TextoDescripcion{
        height: 750px;
    }
    
}

@media screen and (max-width:355px){
    .TextoDescripcion{
        height: 800px;
    }
    
}

@media screen and (max-width:507px){
    .Blog{
        height: 500px;
    }
    
}

@media screen and (max-width:420px) {
    .Caja1{
        height: 400px;
    }
    .Caja2{
        height: 400px;
    }
    .Caja3{
        height: 400px;
    }

    .Transparency{
        top: 2890px;
    }

    .Blog{
        top: 3500px;
    }

    .BackgroundInicio{
        height: 4300px;
    }
}

@media screen and (max-width:410px){
    .Blog{
        height: 600px;
    }
    
}

@media screen and (max-width:353px){
    .descripcion1,.descripcion2,.descripcion3{
       margin-top: -5px;
    }
}

@media screen and (max-width:333px){

    .Blog{
        height: 650px;
    }
}
